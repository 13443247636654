import {
  ChevronIcon,
  CloseIcon,
  DocumentIcon,
  IconProps,
  InfoIcon,
  PlusIcon,
} from 'stories/Icons';

export type GlyphType =
  | 'info'
  | 'chevron-right'
  | 'chevron-left'
  | 'plus'
  | 'pdf'
  | 'close';

type GlyphProps = IconProps & {
  glyph?: GlyphType;
};

export const Glyph = ({ glyph, ...props }: GlyphProps) => {
  switch (glyph) {
    case 'info':
      return <InfoIcon {...props} />;
    case 'chevron-right':
      return <ChevronIcon {...props} variant="right" />;
    case 'chevron-left':
      return <ChevronIcon {...props} variant="left" />;
    case 'plus':
      return <PlusIcon {...props} />;
    case 'pdf':
      return <DocumentIcon {...props} variant="pdf" />;
    case 'close':
      return <CloseIcon {...props} />;
    default:
      return null;
  }
};
