import styled from 'styled-components';

export const MultiInvestorMobileWrapper = styled.div`
  max-height: 90vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MultiInvestorHeaderWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 150%;
  letter-spacing: 0;
  vertical-align: middle;
`;

export const MultiInvestorCloseWrapper = styled.div`
  width: 28px;
  height: 28px;
  padding: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const MultiInvestorOptionsWrapper = styled.div`
  width: 100%;
  min-height: 0;
  padding: ${({ theme }) => theme.spacing[4]};
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorFooterWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;
