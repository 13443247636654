import styled, { css, DefaultTheme } from 'styled-components';

import { StyledBaseButton } from '../BaseButton/styles';
import { KindType, StateType, VariantType } from '../types';

/**
 * Get the color based on the button kind and state.
 */
const getKindColor = (
  kind: KindType,
  state: StateType,
  theme: DefaultTheme
) => {
  const { primary, green: success, red: destructive, grey } = theme.colors;

  if (state === 'inactive') {
    return [grey[300], grey[300], grey[300]] as const;
  }

  switch (kind) {
    case 'neutral':
      return [primary[500], primary[450], primary[600]] as const;
    case 'destructive':
      return [destructive[500], destructive[450], destructive[600]] as const;
    case 'success':
      return [success[500], success[450], success[600]] as const;
    default:
      throw new Error(`Invalid kind ${kind}`);
  }
};

/**
 * Get the styles based on the button variant.
 */
const getVariantStyles = (
  variant: VariantType,
  kind: KindType,
  state: StateType,
  theme: DefaultTheme
) => {
  const [normal] = getKindColor(kind, state, theme);
  const colors = theme.colors;

  switch (variant) {
    case 'primary':
      return css`
        &:hover:not(:disabled),
        &:active:not(:disabled) {
          background-color: ${colors.grey[0]};
          color: ${normal};
          fill: ${normal};
          border: 2px solid ${normal};
        }
      `;
    case 'secondary':
      return css`
        background-color: ${colors.grey[0]};
        border-width: 2px;
        &:hover:not(:disabled),
        &:active:not(:disabled) {
          border-width: 2px;
        }
      `;
    case 'tertiary':
    case 'glyph':
      return css``;
    default:
      throw new Error(`Invalid variant ${variant}`);
  }
};

export const StyledSolycoButton = styled(StyledBaseButton)`
  font-weight: ${({ theme }) => theme.fonts.weight.extrabold};
  text-transform: uppercase;
  letter-spacing: 2px;

  ${({ variant = 'primary', state = 'default', kind = 'neutral', theme }) =>
    getVariantStyles(variant, kind, state, theme)}
`;
