import { ReactNode } from 'react';
import Divider from 'stories/Divider';
import Button from 'ui/Button';

import {
  ChildrenWrapper,
  Main,
  ModalActionsWrapper,
  ModalContent,
  ModalOverlay,
} from './styles';
import { DelayUnmount } from './utils/DelayUnmount';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}

const Modal = ({ children, isOpen, handleClose }: ModalProps) => (
  <DelayUnmount isOpen={isOpen} timeDelay={700}>
    <ModalOverlay>
      <ModalContent isOpen={isOpen} onClick={handleClose}>
        <Main onClick={(e) => e.stopPropagation()}>{children}</Main>
      </ModalContent>
    </ModalOverlay>
  </DelayUnmount>
);

interface ActionType {
  label?: string;
  handler?: VoidFunction;
}

interface MobileModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  hideButtons?: boolean;
  cancelAction?: ActionType;
  confirmAction?: ActionType;
}

export default function MobileModal({
  children,
  isOpen,
  hideButtons = false,
  cancelAction = { label: 'Cancel', handler: () => null },
  confirmAction = { label: 'Save', handler: () => null },
}: MobileModalProps) {
  const handleCancel = () => {
    cancelAction.handler && cancelAction.handler();
  };

  const handleConfirm = () => {
    confirmAction.handler && confirmAction.handler();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleCancel}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Divider isFadeOut height={2} />
      {!hideButtons && (
        <ModalActionsWrapper>
          <Button variant="tertiary" onClick={handleCancel}>
            {cancelAction.label}
          </Button>
          <Button onClick={handleConfirm} width="100%" size="large">
            {confirmAction.label}
          </Button>
        </ModalActionsWrapper>
      )}
    </Modal>
  );
}
