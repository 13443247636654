import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

interface WrapperProps {
  backgroundColor?: string;
  isBackgroundImage?: boolean;
}
export const Wrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<WrapperProps>([
    'backgroundColor',
  ]),
})<WrapperProps>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
		background-color: ${backgroundColor};
	`};
`;

interface ChildrenWrapperProps {
  minHeight: string;
  marginTop: string;
  paddingTop: string;
  paddingBottom: string;
}

export const ChildrenWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<ChildrenWrapperProps>([
    'minHeight',
    'marginTop',
    'paddingTop',
    'paddingBottom',
  ]),
})<ChildrenWrapperProps>`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight};
  margin-top: ${({ marginTop }) => marginTop};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;
