import styled from 'styled-components';
interface HeaderWrapperProps {
  $backgroundColor?: string;
  $hasSecondRow?: boolean;
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  min-height: ${({ $hasSecondRow }) => ($hasSecondRow ? '6.5rem' : '4rem')};
  display: flex;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.grey[0]};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.styles.elevation.elevation1};
`;

export const HeaderV1Wrapper = styled.div`
  padding: 0.695rem 1.25rem 0.695rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
  }
`;

export const HeaderV2Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const HeaderV3OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderV3Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 4rem;
`;

export const HeaderV3MobileSecondRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing[2]}
    ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[2]};
`;

export const HeaderV4Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 30px;
  margin: 0;
  padding: 0;
  margin-left: 1rem;
`;

export const ProfileWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing[3.5]};
`;

export const HeaderV3InvestorAndProfileWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing[3.5]};
  display: flex;
  gap: ${({ theme }) => theme.spacing[2]};
  justify-items: flex-end;
  align-items: center;
`;
