import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface PinInputWrapperProps {
  numDigits: number;
  gap?: string | number;
}

export const PinInputWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<PinInputWrapperProps>([
    'numDigits',
    'gap',
  ]),
})<PinInputWrapperProps>`
  width: 100%;
  display: grid;
  grid-template-columns:
    repeat(${({ numDigits }) => Math.floor(numDigits / 2)}, 1fr)
    1rem repeat(
      ${({ numDigits }) => numDigits - Math.floor(numDigits / 2)},
      1fr
    );
  align-items: center;
  gap: ${({ gap }) => (typeof gap === 'number' ? `${gap}px` : gap)};
`;

interface StyledInputProps extends InputStylesType {}

export const StyledPinInput = styled.input.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledInputProps>`
  ${reusableInputStyles}
  width: 100%;
  height: 48px;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.lg};
  padding: ${({ theme }) => theme.spacing[1.25]}
    ${({ theme }) => theme.spacing[1.5]};
  text-transform: uppercase;
`;

export const Dash = styled.div`
  width: 16px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey[700]};
`;
