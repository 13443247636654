import styled from 'styled-components';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledPasswordInputProps extends InputStylesType {}

export const StyledPasswordInput = styled.input.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledPasswordInputProps>`
  ${reusableInputStyles}
`;
