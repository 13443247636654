import { ModeType } from 'interfaces';
import React from 'react';
import { useTheme } from 'styled-components';
import { SpacingType } from 'utils/helpers/spacingUtils';

import {
  RadioBoxInput,
  RadioBoxLabel,
  RadioBoxLabelsWrapper,
  RadioBoxWrapper,
} from './styles';
import {
  RadioBoxDisplayType,
  RadioBoxLabelType,
  RadioBoxSizeType,
  RadioBoxVariantType,
} from './types';

interface RadioBoxProps extends SpacingType {
  mode?: ModeType;
  display?: RadioBoxDisplayType;
  variant?: RadioBoxVariantType;
  radioSize?: RadioBoxSizeType;
  fullWidth?: boolean;
  name?: string;
  labels?: RadioBoxLabelType[];
  checked?: boolean;
  value?: string;
  color?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
}

export const RadioBox = ({
  mode,
  display = 'inline-flex',
  variant = 'standard',
  radioSize = 'standard',
  fullWidth = false,
  name,
  value,
  labels,
  checked,
  spacing,
  color,
  onClick,
  ...rest
}: RadioBoxProps) => {
  const theme = useTheme();
  const sizeStylesMapper = {
    small: {
      width: 20,
      height: 20,
    },
    standard: {
      width: 24,
      height: 24,
    },
  };

  const labelStylesMapper = {
    option: {
      fontSize: theme.fonts.size.base,
      fontWeight: theme.fonts.weight.normal,
    },
    title: {
      fontSize: theme.fonts.size.base,
      fontWeight: theme.fonts.weight.medium,
    },
    description: {
      fontSize: theme.fonts.size.sm1,
      fontWeight: theme.fonts.weight.normal,
      lineHeight: 1.5,
      color: theme.colors.grey[500],
    },
  };

  const sizeStyles = sizeStylesMapper[radioSize];

  return (
    <RadioBoxWrapper
      display={display}
      variant={variant}
      checked={checked}
      fullWidth={fullWidth}
      spacing={spacing}
      onClick={onClick}
    >
      <RadioBoxInput
        id={name}
        mode={mode}
        type="radio"
        checked={checked}
        name={name}
        value={value}
        {...sizeStyles}
        {...rest}
      />
      {labels?.length && (
        <RadioBoxLabelsWrapper>
          {labels.map((label, index) => {
            const labelStyles = labelStylesMapper[label.type || 'option'];
            return (
              <RadioBoxLabel
                key={index}
                {...labelStyles}
                color={color}
                htmlFor={name}
              >
                {label.text}
              </RadioBoxLabel>
            );
          })}
        </RadioBoxLabelsWrapper>
      )}
    </RadioBoxWrapper>
  );
};
