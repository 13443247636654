import { ModeType } from 'interfaces';
import React, { useEffect, useRef } from 'react';
import { Title } from 'stories/Typography';
import { useTheme } from 'styled-components';

import { getInitStateStylesMapper } from '../helpers';
import { DescriptionMessage, InputWrapper, Wrapper } from '../styles';
import { InputStateType } from '../types';
import { Dash, PinInputWrapper, StyledPinInput } from './styles';

interface PinInputProps {
  mode?: ModeType;
  state?: InputStateType;
  label?: string;
  value?: string;
  errorMessage?: string;
  numDigits?: number;
  width?: string | number;
  gap?: string | number;
  onChange?: (value: string) => void;
}

export const PinInput = ({
  mode = 'light',
  state = 'default',
  label,
  errorMessage,
  numDigits = 8,
  width = 464,
  gap,
  onChange,
}: PinInputProps) => {
  const theme = useTheme();
  if (!gap) {
    gap = theme.spacing[2];
  }
  const stateStyles = getInitStateStylesMapper(mode)[state];
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
  const InputRegex = /^[a-zA-Z\d]$/;

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newInputs = [...inputsRef.current];
    newInputs[index] = event.target;

    const fullPin = newInputs
      .map((input) => input?.value.toLocaleUpperCase() || '')
      .join('');

    onChange?.(fullPin);

    if (event.target.value) {
      if (index < numDigits - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === 'Backspace' && !event.currentTarget.value && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (pasted?: string) => {
    if (typeof pasted === 'string') {
      const keepCharsAndDigits = (character: string) =>
        InputRegex.test(character);

      const digitCodeArray = pasted
        .toUpperCase()
        .split('')
        .filter(keepCharsAndDigits)
        .slice(0, 8);

      onChange?.(digitCodeArray.join(''));

      digitCodeArray.forEach(
        (value, index) => (inputsRef.current[index]!.value = value)
      );
    }
  };

  const dashIndex = Math.floor(numDigits / 2) - 1;

  return (
    <Wrapper width={width}>
      {label && (
        <Title size="medium" color={stateStyles.labelColor}>
          {label}
        </Title>
      )}

      <PinInputWrapper numDigits={numDigits} gap={gap}>
        {Array.from({ length: numDigits }, (_, index) => (
          <React.Fragment key={index}>
            <InputWrapper>
              <StyledPinInput
                id={`pincode-${index}`}
                ref={(el) => (inputsRef.current[index] = el)}
                type="text"
                maxLength={1}
                autoComplete="off"
                disabled={state === 'disabled'}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={(e) => handlePaste(e.clipboardData.getData('text'))}
                data-testid={`pin-input-${index}`}
                {...stateStyles}
              />
            </InputWrapper>
            {index === dashIndex && <Dash className="dash" />}
          </React.Fragment>
        ))}
      </PinInputWrapper>

      {state === 'error' && errorMessage && (
        <DescriptionMessage color={stateStyles.color}>
          {errorMessage}
        </DescriptionMessage>
      )}
    </Wrapper>
  );
};
