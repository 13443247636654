import { ModeType, RefReturn } from 'interfaces';
import { forwardRef } from 'react';
import { Title } from 'stories/Typography';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '../helpers';
import { DescriptionMessage, InputWrapper, Wrapper } from '../styles';
import { InputSizeType, InputStateType } from '../types';
import { StyledEmailInput } from './styles';

interface EmailInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: InputStateType;
  label?: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  width?: string | number;
  name?: string;
}

export const EmailInput = forwardRef(
  (
    {
      mode = 'light',
      size = 'standard',
      state = 'default',
      label,
      value,
      placeholder,
      errorMessage,
      width,
      ...rest
    }: EmailInputProps,
    ref: RefReturn
  ) => {
    const stateStyles = getInitStateStylesMapper(mode)[state];
    const sizeStyles = getInitSizeStylesMapper()[size];

    return (
      <Wrapper width={width}>
        {label && (
          <Title size="medium" color={stateStyles.labelColor}>
            {label}
          </Title>
        )}

        <InputWrapper>
          <StyledEmailInput
            type="email"
            ref={ref}
            value={value}
            placeholder={placeholder}
            disabled={state === 'disabled'}
            data-testid="email-input"
            {...rest}
            {...stateStyles}
            {...sizeStyles}
          />
        </InputWrapper>

        {state === 'error' && errorMessage && (
          <DescriptionMessage color={stateStyles.color}>
            {errorMessage}
          </DescriptionMessage>
        )}
      </Wrapper>
    );
  }
);
