import styled from 'styled-components';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledMoneyInputProps extends InputStylesType {}

export const StyledMoneyInput = styled.input.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledMoneyInputProps>`
  ${reusableInputStyles}

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
