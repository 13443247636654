import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
	0% { transform: translateY(100%); opacity: 0;}
	100% { transform: translateY(0); opacity: 1;}
`;

const slideOut = keyframes`
	0% { transform: translateY(0);}
	100% { transform: translateY(100%);}
`;

interface ModalContentProps {
  isOpen?: boolean;
}

export const ModalContent = styled.div<ModalContentProps>`
  background: ${({ theme }) => theme.colors.grey[0]};
  width: 100%;
  max-height: 90%;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]});

  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.5s ease-in-out
    forwards;
`;

export const Main = styled.main`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[2]};
  align-items: center;
  justify-content: end;
  padding: ${({ theme }) => theme.spacing[4]};
`;

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
