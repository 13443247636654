import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

interface MultiInvestorDimProps {
  isOpen: boolean;
}

export const MultiInvestorDim = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<MultiInvestorDimProps>([
    'isOpen',
  ]),
})<MultiInvestorDimProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: transparent;
  width: 100vw;
  height: 100vh;

  transition: background-color 500ms;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen && `visibility: visible;background-color:rgba(0, 0, 0, 0.3)`};
`;

interface MultiInvestorWrapperProps {
  isOpen: boolean;
}

export const MultiInvestorWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardPropsWithKeys<MultiInvestorWrapperProps>([
    'isOpen',
  ]),
})<MultiInvestorWrapperProps>`
  position: fixed;
  top: 0;
  z-index: 6;
  width: 426px;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[4]};

  transition: 500ms;
  visibility: hidden;
  ${({ isOpen }) =>
    isOpen ? `visibility: visible;right: 0px;` : `right: -450px;`};
`;

export const MultiInvestorChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.grey[0]};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MultiInvestorHeaderWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 150%;
  letter-spacing: 0;
  vertical-align: middle;
`;

export const MultiInvestorCloseWrapper = styled.div`
  width: 28px;
  height: 28px;
  padding: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const MultiInvestorOptionsWrapper = styled.div`
  width: 100%;
  min-height: 0;
  padding: ${({ theme }) => theme.spacing[4]};
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const MultiInvestorFooterWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;
