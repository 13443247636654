import { ModeType } from 'interfaces';
import styled from 'styled-components';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

import { RadioBoxDisplayType, RadioBoxVariantType } from './types';

const sharedAfterStyles = `
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

interface RadioBoxWrapperProps extends SpacingType {
  display: RadioBoxDisplayType;
  variant: RadioBoxVariantType;
  checked?: boolean;
  fullWidth?: boolean;
}

export const RadioBoxWrapper = styled.div<RadioBoxWrapperProps>`
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: ${({ display }) => display};
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  cursor: pointer;
  border: 1px solid transparent;

  ${({ variant, theme }) =>
    variant === 'elevated-card' &&
    `
		background-color: ${theme.colors.grey[0]};
		padding: ${theme.spacing[4]};
		border-radius: ${theme.styles.borderRadius};
		box-shadow: ${theme.styles.elevation.elevation1};
	`}

  ${({ variant, theme }) =>
    variant === 'outlined-card' &&
    `
		background-color: ${theme.colors.grey[0]};
		padding: ${theme.spacing[3]} ${theme.spacing[4]} ;
		border-radius: ${theme.styles.borderRadius};
		border: 1px solid ${theme.colors.grey[300]};
	`}
	
  ${({ variant, checked, theme }) =>
    variant === 'elevated-card' && checked
      ? `
			border: 1px solid ${theme.colors.primary[500]};
	`
      : variant === 'elevated-card' &&
        `
			&:hover {
				box-shadow: ${theme.styles.elevation.elevation2};
			}
		
			&:active {
				box-shadow: ${theme.styles.elevation.press};
			}
	`}


		  ${({ variant, checked, theme }) =>
    variant === 'outlined-card' && checked
      ? `
			border-color: ${theme.colors.primary[500]};
	`
      : variant === 'outlined-card' &&
        `
			&:hover {
				border-color: ${theme.colors.grey[400]};
			}
		
			&:active {
				border-color: ${theme.colors.grey[500]};
			}
	`}


	&:hover input:not(:checked)::after {
    ${sharedAfterStyles}
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }

  &:active input:not(:checked)::after {
    ${sharedAfterStyles}
    background-color: ${({ theme }) => theme.colors.grey[400]};
  }

  &:hover input:not(:checked) {
    border: 2px solid ${({ theme }) => theme.colors.grey[400]};
  }

  &:active input:not(:checked) {
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  }

  ${spacingUtils}
`;

interface RadioBoxInputProps {
  width: number;
  height: number;
  mode?: ModeType;
}

export const RadioBoxInput = styled.input<RadioBoxInputProps>`
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  appearance: none;
  position: relative;
  border-radius: 100%;
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};

  &:checked {
    &::after {
      ${sharedAfterStyles}
      background-color: ${({ theme }) => theme.colors.primary[500]};
    }
    border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  }

  ${({ mode, theme }) =>
    mode === 'dark' &&
    `
		background-color: transparent;
		border-color: ${theme.colors.grey[400]};
		&:checked {
			border-color: ${theme.colors.grey[0]};
  	}
	`}
`;

export const RadioBoxLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1]};
`;

interface RadioBoxLabelProps {
  fontSize: string;
  fontWeight: string;
  lineHeight?: number;
  color?: string;
}

export const RadioBoxLabel = styled.label<RadioBoxLabelProps>`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  cursor: pointer;

  ${({ lineHeight }) =>
    lineHeight &&
    `
		line-height: ${lineHeight};
	`}

  ${({ color }) =>
    color &&
    `
		color: ${color};
	`}
`;
