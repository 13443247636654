import React, { useState } from 'react';
import { SupervisedUserIcon } from 'stories/Icons/SupervisedUser';
import { useTheme } from 'styled-components';

import { MultiInvestorButtonWrapper } from './styles';
import { MultiInvestorButtonProps } from './types';

export default function MultiInvestorButton({
  disabled,
  optionSelected,
  handleClick,
}: MultiInvestorButtonProps) {
  const theme = useTheme();

  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseEnter = () => setIsHover(true);

  const handleMouseDown = () => {
    setIsHover(false);
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setIsPressed(false);
  };

  const getFillColor = () => {
    let color: string = theme.colors.grey[400];
    if (!isHover && !isPressed) {
      color = optionSelected
        ? theme.client === 'solyco'
          ? theme.colors.primary[700]
          : theme.colors.primary[500]
        : theme.colors.grey[400];
    }
    if (isHover) {
      color = optionSelected
        ? theme.client === 'solyco'
          ? theme.colors.primary[800]!
          : theme.colors.primary[600]
        : theme.colors.grey[500];
    }
    if (isPressed) {
      color = optionSelected
        ? theme.client === 'solyco'
          ? theme.colors.primary[800]!
          : theme.colors.primary[700]
        : theme.colors.grey[600];
    }
    return color;
  };

  return (
    <MultiInvestorButtonWrapper
      disabled={disabled}
      optionSelected={optionSelected}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <SupervisedUserIcon
        width={isPressed ? '24px' : '20px'}
        height={isPressed ? '24px' : '20px'}
        fillColor={getFillColor()}
      />
    </MultiInvestorButtonWrapper>
  );
}
