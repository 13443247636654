// Contains shared styled components  between the different types of inputs

import styled, { css } from 'styled-components';

import { InputStylesType } from './types';

export const reusableInputStyles = css<InputStylesType>`
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: ${({ theme }) => theme.fonts.size.base};
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  cursor: ${({ cursor }) => cursor};

  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};

  ${({ paddingLeft }) =>
    paddingLeft &&
    `
			padding-left: ${
        typeof paddingLeft === 'number' ? `${paddingLeft}px` : paddingLeft
      };
	`}

  ${({ paddingRight }) =>
    paddingRight &&
    `
		padding-right: ${
      typeof paddingRight === 'number' ? `${paddingRight}px` : paddingRight
    };
	`}

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }

  &:hover {
    outline: none;
    border: 2px solid ${({ hoverBorderColor }) => hoverBorderColor};
  }
  &:active {
    outline: none;
    border: 1px solid ${({ pressedBorderColor }) => pressedBorderColor};
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ focusBorderColor }) => focusBorderColor};
  }

  transition: border-color 100ms ease;
`;

interface Wrapper {
  width?: string | number;
}

export const Wrapper = styled.div<Wrapper>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
`;

interface DescriptionMessageProps {
  color?: string;
}

export const DescriptionMessage = styled.p<DescriptionMessageProps>`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ color, theme }) => color || theme.colors.grey[400]};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

interface InputIconWrapperProps {
  startIcon?: boolean;
  cursor?: boolean;
  color?: string;
}

export const InputIconWrapper = styled.div<InputIconWrapperProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ cursor }) => (cursor ? 'true' : 'false')};

  ${({ startIcon, theme }) =>
    startIcon ? `left: ${theme.spacing[3]};` : `right: ${theme.spacing[3]};`}

  ${({ color }) =>
    color &&
    `
		color: ${color};
	`}
`;
