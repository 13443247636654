import { ModeType } from 'interfaces';
import ReactPhoneInput from 'react-phone-input-2';
import theme from 'stories/theme';
import { Title } from 'stories/Typography';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '../helpers';
import { DescriptionMessage, InputWrapper, Wrapper } from '../styles';
import { InputSizeType, InputStateType } from '../types';
import { PhoneInputBox } from './styles';

interface PhoneInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: InputStateType;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  width?: string | number;
  onChange?: (e: any) => void;
}

export const PhoneInput = ({
  mode = 'light',
  size = 'standard',
  state = 'default',
  errorMessage,
  label,
  placeholder = 'Enter phone number',
  width,
  ...rest
}: PhoneInputProps) => {
  const stateStyles = getInitStateStylesMapper(mode)[state];
  const initSizeStylesMapper = getInitSizeStylesMapper();

  const sizesStylesMapper = {
    small: {
      ...initSizeStylesMapper.small,
      paddingLeft: theme.spacing[10],
    },
    standard: {
      ...initSizeStylesMapper.standard,
      paddingLeft: theme.spacing[10],
    },
    large: {
      ...initSizeStylesMapper.large,
      paddingLeft: theme.spacing[10],
    },
  };

  const sizeStyles = sizesStylesMapper[size];

  return (
    <>
      <Wrapper width={width}>
        {label && (
          <Title size="medium" color={stateStyles.labelColor}>
            {label}
          </Title>
        )}
        <InputWrapper>
          <PhoneInputBox {...stateStyles} {...sizeStyles}>
            <ReactPhoneInput
              placeholder={placeholder}
              country="us"
              {...rest}
              inputProps={{
                'data-testid': 'phone-input',
              }}
            />
          </PhoneInputBox>
        </InputWrapper>

        {state === 'error' && errorMessage && (
          <DescriptionMessage color={stateStyles.color}>
            {errorMessage}
          </DescriptionMessage>
        )}
      </Wrapper>
    </>
  );
};
