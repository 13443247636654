import styled from 'styled-components';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledEmailInputProps extends InputStylesType {}

export const StyledEmailInput = styled.input.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledEmailInputProps>`
  ${reusableInputStyles}
`;
