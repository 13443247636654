import React from 'react';

import type { ButtonProps } from '../types';
import { StyledBaseButton } from './styles';

interface BaseButtonProps extends ButtonProps {
  hasGlyph?: boolean;
}

export default function BaseButton({ children, ...rest }: BaseButtonProps) {
  return <StyledBaseButton {...rest}>{children}</StyledBaseButton>;
}
