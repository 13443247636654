import { ModeType, RefTextAreaReturn } from 'interfaces';
import { forwardRef, useEffect, useState } from 'react';
import { Title } from 'stories/Typography';

import { getInitStateStylesMapper } from '../helpers';
import { DescriptionMessage, InputWrapper, Wrapper } from '../styles';
import { InputStateType } from '../types';
import { StyledLongInput } from './styles';

interface LongInputProps {
  mode?: ModeType;
  state?: InputStateType;
  label?: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  width?: string | number;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
}

export const LongInput = forwardRef(
  (
    {
      mode = 'light',
      state = 'default',
      label,
      value: initValue,
      placeholder,
      errorMessage,
      width,
      maxLength,
      className,
      inputClassName,
      ...rest
    }: LongInputProps,
    ref: RefTextAreaReturn
  ) => {
    const stateStyles = getInitStateStylesMapper(mode)[state];

    const [value, setValue] = useState(initValue);

    const handleChange = (value: string) => {
      setValue(value);
    };

    useEffect(() => {
      setValue(initValue);
    }, [initValue]);

    return (
      <Wrapper className={className} width={width}>
        {label && (
          <Title size="medium" color={stateStyles.labelColor}>
            {label}
          </Title>
        )}

        <InputWrapper>
          <StyledLongInput
            ref={ref}
            className={inputClassName}
            value={value}
            placeholder={placeholder}
            disabled={state === 'disabled'}
            maxLength={maxLength}
            onChange={(e) => handleChange(e.target.value)}
            data-testid="long-input"
            {...rest}
            {...stateStyles}
          />
        </InputWrapper>

        {state == 'default' && typeof maxLength === 'number' && (
          <DescriptionMessage>
            {maxLength - (value?.length || 0)}
            {maxLength > 1 ? ` Characters Remaining` : ` Character Remaining`}
          </DescriptionMessage>
        )}

        {state === 'error' && errorMessage && (
          <DescriptionMessage color={stateStyles.color}>
            {errorMessage}
          </DescriptionMessage>
        )}
      </Wrapper>
    );
  }
);
