import styled from 'styled-components';

export const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};

  fill: inherit;
`;

export const LoaderChildrenHidden = styled.div`
  visibility: hidden;
  width: 100%;
  fill: inherit;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  gap: 2rem;
`;
