import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.black[300]};
  padding: 2rem 10rem;
  z-index: 99;
`;
export const Text = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: 325;
  color: ${({ theme }) => theme.colors.grey[0]};
`;
export const Navigation = styled.div`
  display: flex;
  gap: 4rem;
`;
