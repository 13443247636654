import styled from 'styled-components';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledInputProps extends InputStylesType {}

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledInputProps>`
  ${reusableInputStyles}
`;
