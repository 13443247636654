import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

interface MultiInvestorPillWrapperProps {
  disabled?: boolean;
  isSmallMobile?: boolean;
}

export const MultiInvestorPillWrapper = styled.div.withConfig({
  shouldForwardProp:
    shouldNotForwardPropsWithKeys<MultiInvestorPillWrapperProps>([
      'disabled',
      'isSmallMobile',
    ]),
})<MultiInvestorPillWrapperProps>`
  width: fit-content;
  max-width: ${({ isSmallMobile }) => (isSmallMobile ? '300px' : '350px')};
  height: 32px;
  border-radius: 100px;
  padding: 0 ${({ theme }) => theme.spacing[2]};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[1]};

  background-color: ${({ theme }) =>
    theme.client === 'solyco'
      ? theme.colors.primary[50]
      : theme.colors.primary[100]};
  border: 1px solid
    ${({ theme }) =>
      theme.client === 'solyco'
        ? theme.colors.primary[700]
        : theme.colors.primary[500]};

  &:hover {
    border: 1px solid
      ${({ theme }) =>
        theme.client === 'solyco'
          ? theme.colors.primary[800]
          : theme.colors.primary[600]};
  }

  &:active {
    border: 1px solid
      ${({ theme }) =>
        theme.client === 'solyco'
          ? theme.colors.primary[800]
          : theme.colors.primary[700]};
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const MultiInvestorPillLeftWrapper = styled.div`
  height: 28px;
  flex: 1;
  padding: ${({ theme }) => theme.spacing[1]} 0;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
`;

export const MultiInvestorPillLabel = styled.p.withConfig({
  shouldForwardProp:
    shouldNotForwardPropsWithKeys<MultiInvestorPillWrapperProps>(['disabled']),
})<MultiInvestorPillWrapperProps>`
  margin: 0;
  max-width: ${({ disabled, isSmallMobile }) => {
    if (isSmallMobile) {
      return disabled ? '284px' : '223px';
    } else {
      return disabled ? '331px' : '275px';
    }
  }};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  color: ${({ theme }) =>
    theme.client === 'solyco'
      ? theme.colors.primary[700]
      : theme.colors.primary[500]};
`;

export const MultiInvestorPillDividerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2px 0;
`;

export const MultiInvestorPillArrowWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  transition: all 0.1s ease;

  ${MultiInvestorPillLeftWrapper}:hover & {
    padding-top: 2px;
  }
`;

export const MultiInvestorCloseWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
