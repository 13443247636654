import React from 'react';

import type { ButtonProps } from '../types';
import { StyledSolycoButton } from './styles';

interface SolycoButtonProps extends ButtonProps {
  hasGlyph?: boolean;
}

export default function SolycoButton({ children, ...rest }: SolycoButtonProps) {
  return <StyledSolycoButton {...rest}>{children}</StyledSolycoButton>;
}
