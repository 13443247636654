import React from 'react';
import Divider from 'stories/Divider';
import { ChevronIcon, CloseIcon, PersonIcon } from 'stories/Icons';
import { useTheme } from 'styled-components';

import {
  MultiInvestorCloseWrapper,
  MultiInvestorPillArrowWrapper,
  MultiInvestorPillDividerWrapper,
  MultiInvestorPillLabel,
  MultiInvestorPillLeftWrapper,
  MultiInvestorPillWrapper,
} from './styles';
import { MultiInvestorPillProps } from './types';

export default function MultiInvestorPill({
  label,
  disabled = false,
  isSmallMobile,
  handleClick,
  handleClearPillOption,
}: MultiInvestorPillProps) {
  const theme = useTheme();

  const longestLabel = isSmallMobile ? 20 : 25;
  const longestLabelDisabled = isSmallMobile ? 26 : 30;

  return (
    <MultiInvestorPillWrapper disabled={disabled} isSmallMobile={isSmallMobile}>
      <MultiInvestorPillLeftWrapper onClick={handleClick}>
        {!disabled ? (
          label.length <= longestLabel ? (
            <PersonIcon
              width="20"
              height="20"
              fillColor={
                theme.client === 'solyco'
                  ? theme.colors.primary[700]
                  : theme.colors.primary[500]
              }
            />
          ) : null
        ) : label.length <= longestLabelDisabled ? (
          <PersonIcon
            width="20"
            height="20"
            fillColor={
              theme.client === 'solyco'
                ? theme.colors.primary[700]
                : theme.colors.primary[500]
            }
          />
        ) : null}
        <MultiInvestorPillLabel
          disabled={disabled}
          isSmallMobile={isSmallMobile}
        >
          {label}
        </MultiInvestorPillLabel>
        {!disabled && (
          <MultiInvestorPillArrowWrapper>
            <ChevronIcon
              height="20"
              width="20"
              variant="down"
              fillColor={
                theme.client === 'solyco'
                  ? theme.colors.primary[700]
                  : theme.colors.primary[500]
              }
            />
          </MultiInvestorPillArrowWrapper>
        )}
      </MultiInvestorPillLeftWrapper>
      {!disabled && (
        <MultiInvestorPillDividerWrapper>
          <Divider
            direction="vertical"
            spacing={{ mb: 3, mt: 3 }}
            color={
              theme.client === 'solyco'
                ? theme.colors.primary[700]
                : theme.colors.primary[500]
            }
          />
        </MultiInvestorPillDividerWrapper>
      )}
      {!disabled && (
        <MultiInvestorCloseWrapper onClick={handleClearPillOption}>
          <CloseIcon
            width="20"
            height="20"
            fillColor={
              theme.client === 'solyco'
                ? theme.colors.primary[700]
                : theme.colors.primary[500]
            }
          />
        </MultiInvestorCloseWrapper>
      )}
    </MultiInvestorPillWrapper>
  );
}
