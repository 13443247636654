import { Glyph } from 'stories/Glyph';
import { useTheme } from 'styled-components';
import Loader from 'ui/Loader';

import BaseButton from './BaseButton';
import SolycoButton from './SolycoButton';
import { ChildrenWrapper, LoaderChildrenHidden, LoaderWrapper } from './styles';
import type { ButtonProps } from './types';

const ClientButton = (props: ButtonProps) => {
  const theme = useTheme();

  if (theme.client === 'solyco') {
    return <SolycoButton {...props} hasGlyph={!!props.glyphName} />;
  }
  return <BaseButton {...props} hasGlyph={!!props.glyphName} />;
};

export default function Button({ children, ...props }: ButtonProps) {
  const {
    state = 'default',
    variant = 'primary',
    size = 'standard',
    glyphName,
    onClick,
  } = props;

  const isDisabled = ['inactive', 'loading'].includes(state);

  const glyphSizeMapper = {
    small: '24',
    standard: '24',
    large: variant === 'glyph' ? '32' : '24',
  };

  const glyphSize = glyphSizeMapper[size];

  const ChildrenContent = (
    <>
      {glyphName && (
        <Glyph
          fillColor="inherit"
          glyph={glyphName || 'plus'}
          width={glyphSize}
          height={glyphSize}
        />
      )}
      {children}
    </>
  );

  return (
    <ClientButton
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      {...props}
    >
      {state === 'loading' ? (
        <>
          <ChildrenWrapper>
            <LoaderChildrenHidden>{ChildrenContent}</LoaderChildrenHidden>
            <LoaderWrapper>
              <Loader fromColor="inherit" toColor="inherit" />
            </LoaderWrapper>
          </ChildrenWrapper>
        </>
      ) : variant === 'glyph' ? (
        <Glyph
          fillColor={'inherit'}
          glyph={glyphName || 'plus'}
          width={glyphSize}
          height={glyphSize}
        />
      ) : (
        <ChildrenWrapper>{ChildrenContent}</ChildrenWrapper>
      )}
    </ClientButton>
  );
}
