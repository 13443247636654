import { ModeType, RefReturn } from 'interfaces';
import { forwardRef, useState } from 'react';
import { EyeClosedIcon, EyeOpenIcon } from 'stories/Icons';
import { Title } from 'stories/Typography';
import { useTheme } from 'styled-components';

import { getInitSizeStylesMapper, getInitStateStylesMapper } from '../helpers';
import {
  DescriptionMessage,
  InputIconWrapper,
  InputWrapper,
  Wrapper,
} from '../styles';
import { InputSizeType } from '../types';
import { StyledPasswordInput } from './styles';

interface PasswordInputProps {
  mode?: ModeType;
  size?: InputSizeType;
  state?: 'default' | 'disabled' | 'error' | 'matching';
  label?: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  width?: string | number;
  name?: string;
}

export const PasswordInput = forwardRef(
  (
    {
      mode = 'light',
      size = 'standard',
      state = 'default',
      label,
      value,
      placeholder,
      errorMessage,
      width,
      ...rest
    }: PasswordInputProps,
    ref: RefReturn
  ) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);

    const initStateStylesMapper = getInitStateStylesMapper(mode);
    const initSizeStylesMapper = getInitSizeStylesMapper();

    const stateStylesMapper = {
      ...initStateStylesMapper,
      matching: {
        ...initStateStylesMapper.default,
        border: ` 1px solid ${theme.colors.green[500]}`,
        focusBorderColor: theme.colors.green[500],
      },
    };

    const stateStyles = stateStylesMapper[state];

    const sizeStylesMapper = {
      small: {
        ...initSizeStylesMapper.small,
        paddingRight: theme.spacing[10],
      },
      standard: {
        ...initSizeStylesMapper.standard,
        paddingRight: theme.spacing[10],
      },
      large: {
        ...initSizeStylesMapper.large,
        paddingRight: theme.spacing[10],
      },
    };

    const sizeStyles = sizeStylesMapper[size];

    return (
      <Wrapper width={width}>
        {label && (
          <Title size="medium" color={stateStyles.labelColor}>
            {label}
          </Title>
        )}

        <InputWrapper>
          <StyledPasswordInput
            ref={ref}
            type={showPassword ? 'text' : 'password'}
            value={value}
            placeholder={placeholder}
            disabled={state === 'disabled'}
            data-testid="password-input"
            {...rest}
            {...stateStyles}
            {...sizeStyles}
          />
          <InputIconWrapper
            onClick={() => setShowPassword((showPassword) => !showPassword)}
          >
            {showPassword ? (
              <EyeClosedIcon fillColor={theme.colors.primary[500]} />
            ) : (
              <EyeOpenIcon fillColor={theme.colors.primary[500]} />
            )}
          </InputIconWrapper>
        </InputWrapper>

        {state === 'error' && errorMessage && (
          <DescriptionMessage color={stateStyles.color}>
            {errorMessage}
          </DescriptionMessage>
        )}
      </Wrapper>
    );
  }
);
