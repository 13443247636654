import { ReactNode, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  timeDelay: number;
}
export function DelayUnmount({ children, isOpen, timeDelay }: Props) {
  const [isMounted, setMounted] = useState(false);
  let delayTimeout: null | ReturnType<typeof setTimeout> = null;

  useEffect(() => {
    if (isOpen && !isMounted) {
      setMounted(true);
    } else if (!isOpen && isMounted) {
      delayTimeout = setTimeout(() => setMounted(false), timeDelay);
    }
    () => clearTimeout(Number(delayTimeout));
  }, [isOpen, isMounted]);

  return <>{isMounted ? children : null}</>;
}
