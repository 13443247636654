import {
  SettingsGetPersonalInfoDetails,
  UserJobResultDetails,
} from 'interfaces/graphql.types';

export const getInitialsFromUserJob = (
  userJob?: UserJobResultDetails | SettingsGetPersonalInfoDetails
) => {
  if (!userJob) return '';
  let firstName = '';
  let lastName = '';
  if (userJob?.__typename === 'UserJobResultDetails') {
    firstName = userJob.contact?.firstName || '';
    lastName = userJob.contact?.lastName || '';
  } else if (userJob?.__typename === 'SettingsGetPersonalInfoDetails') {
    firstName = userJob?.firstName || '';
    lastName = userJob?.lastName || '';
  }

  return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
};
