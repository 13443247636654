import React, { useEffect, useState } from 'react';
import Button from 'stories/Button';
import Divider from 'stories/Divider';
import { CloseIcon } from 'stories/Icons';
import { SearchInput } from 'stories/InputFields';
import { RadioBox } from 'stories/RadioBox';
import { useTheme } from 'styled-components';

import {
  MultiInvestorChildrenWrapper,
  MultiInvestorCloseWrapper,
  MultiInvestorDim,
  MultiInvestorFooterWrapper,
  MultiInvestorHeaderWrapper,
  MultiInvestorOptionsWrapper,
  MultiInvestorTitle,
  MultiInvestorTitleWrapper,
  MultiInvestorWrapper,
} from './styles';
import { MultiInvestorSidebarProps } from './types';

export default function MultiInvestorSidebar({
  isOpen,
  options,
  selectedOptionPk,
  title,
  handleClose,
  handleApply,
  handleClear,
}: MultiInvestorSidebarProps) {
  const theme = useTheme();
  const [checkedOption, setCheckedOption] = useState(selectedOptionPk);
  const [searchString, setSearchString] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [shouldClearSearch, setShouldClearSearch] = useState(false);

  // needed to update checked when selected investor is cleared from the pill after the first render
  useEffect(() => {
    setCheckedOption(selectedOptionPk);
  }, [selectedOptionPk]);

  useEffect(() => {
    if (!shouldClearSearch) return;

    const searchClearTimeout = setTimeout(() => {
      handleSearchStringChange('');
      setShouldClearSearch(false);
    }, 501);

    return () => clearTimeout(searchClearTimeout);
  }, [shouldClearSearch]);

  const handleSearchStringChange = (text: string) => {
    setSearchString(text);

    if (text === '') setFilteredOptions(options);
    else {
      setFilteredOptions(
        options.filter((opt) =>
          opt.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  const handleCloseClick = () => {
    // clicking backing overlay or x button - reset checked to selected, clear search, & close
    setCheckedOption(selectedOptionPk);
    setShouldClearSearch(true);
    handleClose();
  };

  const handleClearClick = () => {
    // empty out checked and search, handle clear, & close
    setCheckedOption('');
    setShouldClearSearch(true);
    handleClear();
    handleClose();
  };

  const handleApplyClick = () => {
    // apply the checked option, clear the search, & close
    handleApply(checkedOption);
    setShouldClearSearch(true);
    handleClose();
  };

  const handleOptionClick = (optionPk: string) => {
    setCheckedOption(optionPk);
  };

  return (
    <>
      <MultiInvestorDim isOpen={isOpen} onClick={handleCloseClick} />
      <MultiInvestorWrapper isOpen={isOpen}>
        <MultiInvestorChildrenWrapper>
          <MultiInvestorHeaderWrapper>
            <MultiInvestorTitleWrapper>
              <MultiInvestorTitle>{title}</MultiInvestorTitle>
              <MultiInvestorCloseWrapper onClick={handleCloseClick}>
                <CloseIcon fillColor={theme.colors.grey[400]} />
              </MultiInvestorCloseWrapper>
            </MultiInvestorTitleWrapper>
            <SearchInput
              width="100%"
              searchIconColor={theme.colors.grey[400]}
              value={searchString}
              onChange={handleSearchStringChange}
            />
          </MultiInvestorHeaderWrapper>
          <Divider isFadeOut={true} />
          <MultiInvestorOptionsWrapper>
            {filteredOptions.map((option, i) => (
              <RadioBox
                mode="light"
                key={'investor-option-' + i}
                name={'investor-option-' + i}
                value={option.pk}
                labels={[{ text: option.name, type: 'description' }]}
                checked={option.pk === checkedOption}
                fullWidth={true}
                display="flex"
                variant="outlined-card"
                spacing={{
                  pt: theme.spacing[4],
                  pl: theme.spacing[4],
                  pb: theme.spacing[4],
                  pr: theme.spacing[4],
                }}
                onClick={() => handleOptionClick(option.pk)}
              />
            ))}
          </MultiInvestorOptionsWrapper>
          <MultiInvestorFooterWrapper>
            <Button
              variant="secondary"
              size="large"
              width="50%"
              state={checkedOption === undefined ? 'inactive' : 'default'}
              onClick={handleClearClick}
            >
              Clear
            </Button>
            <Button
              variant="primary"
              size="large"
              width="50%"
              state={checkedOption === undefined ? 'inactive' : 'default'}
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </MultiInvestorFooterWrapper>
        </MultiInvestorChildrenWrapper>
      </MultiInvestorWrapper>
    </>
  );
}
