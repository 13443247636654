import styled from 'styled-components';
import { colorWithOpacity } from 'utils/helpers/colorWithOpacity';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

interface MultiInvestorButtonWrapperProps {
  disabled?: boolean;
  optionSelected: boolean;
}

export const MultiInvestorButtonWrapper = styled.button.withConfig({
  shouldForwardProp:
    shouldNotForwardPropsWithKeys<MultiInvestorButtonWrapperProps>([
      'disabled',
    ]),
})<MultiInvestorButtonWrapperProps>`
  width: 32px;
  height: 32px;
  padding: ${({ theme }) => theme.spacing[2]};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  transition: all 0.1s ease;

  svg {
    transition: all 0.1s ease;
  }

  background-color: ${({ theme, optionSelected }) =>
    optionSelected
      ? theme.client === 'solyco'
        ? theme.colors.primary[50]
        : theme.colors.primary[100]
      : 'transparent'};
  border: ${({ theme, optionSelected }) =>
    optionSelected
      ? theme.client === 'solyco'
        ? '1px solid ' + theme.colors.primary[700]
        : '1px solid ' + theme.colors.primary[500]
      : 'none'};

  &:hover {
    background-color: ${({ theme, optionSelected }) =>
      optionSelected
        ? theme.client === 'solyco'
          ? theme.colors.primary[50]
          : theme.colors.primary[100]
        : colorWithOpacity(theme.colors.grey[500], 0.1)};

    border: ${({ theme, optionSelected }) =>
      optionSelected
        ? theme.client === 'solyco'
          ? '1px solid ' + theme.colors.primary[800]
          : '1px solid ' + theme.colors.primary[600]
        : 'none'};
  }

  &:active {
    background-color: ${({ theme, optionSelected }) =>
      optionSelected
        ? theme.client === 'solyco'
          ? theme.colors.primary[50]
          : theme.colors.primary[100]
        : colorWithOpacity(theme.colors.grey[600], 0.2)};

    border: ${({ theme, optionSelected }) =>
      optionSelected
        ? theme.client === 'solyco'
          ? '1px solid ' + theme.colors.primary[800]
          : '1px solid ' + theme.colors.primary[700]
        : 'none'};
  }
`;
