import styled from 'styled-components';

import { inputFieldsShouldNotForwardPropsWithKeys } from '../helpers';
import { reusableInputStyles } from '../styles';
import { InputStylesType } from '../types';

interface StyledLongInputProps extends InputStylesType {}

export const StyledLongInput = styled.textarea.withConfig({
  shouldForwardProp: inputFieldsShouldNotForwardPropsWithKeys,
})<StyledLongInputProps>`
  ${reusableInputStyles}

  height: 72px;
`;
