import { useInvestorsAndInvestorEntitiesPicker } from 'providers/InvestorsAndInvestorEntitiesPickerProvider';
import React, { forwardRef, useEffect, useState } from 'react';
import MultiInvestorButton from 'stories/MultiInvestorButton';
import MultiInvestorMobileModal from 'stories/MultiInvestorMobileModal';
import MultiInvestorPill from 'stories/MultiInvestorPill';
import MultiInvestorSidebar from 'stories/MultiInvestorSidebar';
import theme from 'stories/theme';
import LogoNavbar from 'ui/Logos/LogoNavbar';
import { VariantTypes } from 'ui/uiTypes';
import { useWindowSize } from 'utils/hooks/useWindow';

import { Burger } from '../Burger';
import { NavbarLinks } from '../NavbarLinks';
import { Profile } from '../Profile';
import { Sidebar } from '../Sidebar';
import { DataType } from '../types';
import {
  HeaderV1Wrapper,
  HeaderV2Wrapper,
  HeaderV3InvestorAndProfileWrapper,
  HeaderV3MobileSecondRowWrapper,
  HeaderV3OuterWrapper,
  HeaderV3Wrapper,
  HeaderV4Wrapper,
  HeaderWrapper,
  ProfileWrapper,
  Title,
} from './styles';

interface HeaderV1Props extends DataType {
  isMobile?: boolean;
}

const HeaderV1 = forwardRef<HTMLDivElement, HeaderV1Props>((props, ref) => {
  const { isMobile, title } = props;
  return (
    <HeaderWrapper
      ref={ref}
      $backgroundColor={theme.colors.grey[isMobile ? 0 : 600]}
      data-testid="header-wrapper"
    >
      <HeaderV1Wrapper>
        {isMobile ? (
          title && <Title>{title}</Title>
        ) : (
          <LogoNavbar
            width={111}
            height={37}
            variant={'secondary' as VariantTypes}
            strokeWidth={1}
          />
        )}
      </HeaderV1Wrapper>
    </HeaderWrapper>
  );
});

interface HeaderV2Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

const HeaderV2 = forwardRef<HTMLDivElement, HeaderV2Props>((props, ref) => {
  const { isMobile, isMenuOpen, handleToggleMenu } = props;
  return (
    <HeaderWrapper
      ref={ref}
      $backgroundColor={theme.colors.grey[600]}
      data-testid="header-wrapper"
    >
      <HeaderV2Wrapper>
        {isMobile && (
          <Burger isOpen={isMenuOpen} handleClick={handleToggleMenu} />
        )}
        <LogoNavbar
          width={isMobile ? 173 : 111}
          height={isMobile ? 22 : 37}
          variant={'secondary' as VariantTypes}
          strokeWidth={1}
        />
        {!isMobile && <NavbarLinks type="home" />}
      </HeaderV2Wrapper>
      {isMobile && (
        <Sidebar variant={1} isOpen={isMenuOpen} linksType="home" mode="dark" />
      )}
    </HeaderWrapper>
  );
});

interface HeaderV3Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

const HeaderV3 = forwardRef<HTMLDivElement, HeaderV3Props>((props, ref) => {
  const { isMobile, isMenuOpen, handleToggleMenu, handleCloseMenu } = props;

  const [isMultiInvestorViewOpen, setIsMultiInvestorViewOpen] = useState(false);

  const windowSize = useWindowSize();
  const isSmallMobile = Boolean(windowSize.width && windowSize.width <= 400);

  const {
    investor,
    investorPickerData,
    multiInvestorVisible,
    multiInvestorEnabled,
    loadingInvestorsAndInvestorEntities,
    onSelectChangeInvestor,
  } = useInvestorsAndInvestorEntitiesPicker();

  // false when no investor, or when All Investors (default)
  const investorOptionSelected = Boolean(investor?.pk);

  const handleToggleMultiInvestorView = () => {
    if (!multiInvestorEnabled) return;
    setIsMultiInvestorViewOpen((prev) => !prev);
  };

  const handleCloseMultiInvestorView = () => setIsMultiInvestorViewOpen(false);

  const handleApplyInvestorSelection = (selectedInvestorPk: string) => {
    if (selectedInvestorPk === investor?.pk) return;
    onSelectChangeInvestor(selectedInvestorPk);
  };

  const handleClearInvestorSelection = () => {
    if (!investor) return;
    if (investor.pk === '') return;
    onSelectChangeInvestor('');
  };

  return (
    <HeaderWrapper
      ref={ref}
      $backgroundColor={theme.colors.grey[0]}
      data-testid="header-wrapper"
      $hasSecondRow={isMobile && investorOptionSelected}
    >
      <HeaderV3OuterWrapper>
        <HeaderV3Wrapper>
          <Burger
            isOpen={isMenuOpen}
            handleClick={handleToggleMenu}
            color={theme.colors.grey[500]}
            width={isMobile ? 24 : 32}
            height={isMobile ? 24 : 32}
          />
          <LogoNavbar
            width={isMobile ? 173 : 111}
            height={isMobile ? 22 : 37}
            variant={'primary' as VariantTypes}
            strokeWidth={1}
            isSmallMobile={isSmallMobile}
          />
          <HeaderV3InvestorAndProfileWrapper>
            {multiInvestorVisible && (
              <>
                {isMobile ? (
                  <MultiInvestorButton
                    disabled={
                      !multiInvestorEnabled ||
                      loadingInvestorsAndInvestorEntities
                    }
                    optionSelected={investorOptionSelected}
                    handleClick={handleToggleMultiInvestorView}
                  />
                ) : investorOptionSelected ? (
                  <MultiInvestorPill
                    label={investor!.name}
                    disabled={
                      !multiInvestorEnabled ||
                      loadingInvestorsAndInvestorEntities
                    }
                    handleClick={handleToggleMultiInvestorView}
                    handleClearPillOption={handleClearInvestorSelection}
                  />
                ) : (
                  <MultiInvestorButton
                    disabled={
                      !multiInvestorEnabled ||
                      loadingInvestorsAndInvestorEntities
                    }
                    optionSelected={investorOptionSelected}
                    handleClick={handleToggleMultiInvestorView}
                  />
                )}
              </>
            )}
            <Profile isMobile={isMobile} />
          </HeaderV3InvestorAndProfileWrapper>
        </HeaderV3Wrapper>
        {isMobile && multiInvestorVisible && investorOptionSelected && (
          <HeaderV3MobileSecondRowWrapper>
            <MultiInvestorPill
              label={investor!.name}
              disabled={
                !multiInvestorEnabled || loadingInvestorsAndInvestorEntities
              }
              isSmallMobile={isSmallMobile}
              handleClick={handleToggleMultiInvestorView}
              handleClearPillOption={handleClearInvestorSelection}
            />
          </HeaderV3MobileSecondRowWrapper>
        )}
      </HeaderV3OuterWrapper>

      <Sidebar
        variant={isMobile ? 1 : 2}
        isOpen={isMenuOpen}
        linksType="app"
        mode="light"
        handleClose={handleCloseMenu}
      />
      {multiInvestorEnabled && (
        <>
          {isMobile ? (
            <MultiInvestorMobileModal
              isOpen={isMultiInvestorViewOpen}
              options={investorPickerData}
              selectedOptionPk={investor?.pk || ''}
              title="Advisor View"
              handleClose={handleCloseMultiInvestorView}
              handleApply={handleApplyInvestorSelection}
              handleClear={handleClearInvestorSelection}
            />
          ) : (
            <MultiInvestorSidebar
              isOpen={isMultiInvestorViewOpen}
              options={investorPickerData}
              selectedOptionPk={investor?.pk || ''}
              title="Advisor View"
              handleClose={handleCloseMultiInvestorView}
              handleApply={handleApplyInvestorSelection}
              handleClear={handleClearInvestorSelection}
            />
          )}
        </>
      )}
    </HeaderWrapper>
  );
});

interface HeaderV4Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

const HeaderV4 = forwardRef<HTMLDivElement, HeaderV4Props>((props, ref) => {
  const { isMobile, isMenuOpen, handleToggleMenu, handleCloseMenu } = props;
  return (
    <HeaderWrapper
      ref={ref}
      $backgroundColor={theme.colors.grey[600]}
      data-testid="header-wrapper"
    >
      <HeaderV4Wrapper>
        <Burger
          isOpen={isMenuOpen}
          handleClick={handleToggleMenu}
          color={theme.colors.grey[400]}
          pressColor={theme.colors.grey[0]}
          width={isMobile ? 24 : 32}
          height={isMobile ? 24 : 32}
          mode="dark"
        />
        <LogoNavbar
          width={isMobile ? 173 : 111}
          height={isMobile ? 22 : 37}
          variant={'secondary' as VariantTypes}
          strokeWidth={1}
        />
        <ProfileWrapper>
          <Profile isMobile={isMobile} />
        </ProfileWrapper>
      </HeaderV4Wrapper>

      <Sidebar
        variant={isMobile ? 1 : 3}
        isOpen={isMenuOpen}
        linksType="platform-manager"
        mode="dark"
        handleClose={handleCloseMenu}
      />
    </HeaderWrapper>
  );
});

interface HeaderV5Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

const HeaderV5 = forwardRef<HTMLDivElement, HeaderV5Props>((props, ref) => {
  const { isMobile, isMenuOpen, handleToggleMenu, handleCloseMenu } = props;
  return (
    <HeaderWrapper
      ref={ref}
      $backgroundColor={theme.colors.grey[600]}
      data-testid="header-wrapper"
    >
      <HeaderV4Wrapper>
        <Burger
          isOpen={isMenuOpen}
          handleClick={handleToggleMenu}
          color={theme.colors.grey[400]}
          pressColor={theme.colors.grey[0]}
          width={isMobile ? 24 : 32}
          height={isMobile ? 24 : 32}
          mode="dark"
        />
        <LogoNavbar
          width={isMobile ? 173 : 111}
          height={isMobile ? 22 : 37}
          variant={'secondary' as VariantTypes}
          strokeWidth={1}
        />
        <ProfileWrapper>
          <Profile isMobile={isMobile} />
        </ProfileWrapper>
      </HeaderV4Wrapper>

      <Sidebar
        variant={isMobile ? 1 : 3}
        isOpen={isMenuOpen}
        linksType="crm"
        mode="dark"
        handleClose={handleCloseMenu}
      />
    </HeaderWrapper>
  );
});

interface HeaderProps {
  isMobile?: boolean;
  variant: number;
  data: DataType;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const { isMobile, variant, data } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen !== undefined) {
      setIsMenuOpen(undefined);
    }
    return () => setIsMenuOpen(undefined);
  }, [isMobile]);

  switch (variant) {
    case 1:
      return <HeaderV1 ref={ref} isMobile={isMobile} {...data} />;
    case 2:
      return (
        <HeaderV2
          ref={ref}
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleToggleMenu={handleToggleMenu}
        />
      );
    case 3:
      return (
        <HeaderV3
          ref={ref}
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleToggleMenu={handleToggleMenu}
          handleCloseMenu={handleCloseMenu}
        />
      );
    case 4:
      return (
        <HeaderV4
          ref={ref}
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleToggleMenu={handleToggleMenu}
          handleCloseMenu={handleCloseMenu}
        />
      );
    case 5:
      return (
        <HeaderV5
          ref={ref}
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleToggleMenu={handleToggleMenu}
          handleCloseMenu={handleCloseMenu}
        />
      );
    default:
      return null;
  }
});

export default Header;
